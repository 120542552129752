import {Component, OnInit, ViewChild} from '@angular/core';

const screenfull = require('screenfull');
const browser = require('jquery.browser');
declare var $: any;

import {SettingsService} from '../../core/settings/settings.service';
import {MenuService} from '../../core/menu/menu.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {environment} from '../../../environments/environment';
import {AngularFireDatabase} from 'angularfire2/database';
import {TokenService} from '../../models/services/token.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  navCollapsed = true; // for horizontal layout
  menuItems = []; // for horizontal layout
  schoolFireNumber: String;
  adminNotifications: notification[] = [];
  advisorNotifications: notification[] = [];
  public notificationCount = 0;
  public voucherCount = 0;
  public textNotifiPopUp: String = '';
  public keyNotifiPopUp: String = '';
  public countnews = 0;
  public countnotifications: 0;
  public countnotshipped = 0;
  public companyName;
  isNavSearchVisible: boolean;
  openNoveltiesModal: boolean = false;
  @ViewChild('fsbutton') fsbutton; // the fullscreen button

  constructor(
      public menu: MenuService,
      public settings: SettingsService,
      private db: AngularFireDatabase,
      private afAuth: AngularFireAuth,
      private token: TokenService
  ) {
  }

  ngOnInit() {
    this.isNavSearchVisible = false;
    const company = this.token.USER;
    // this.companyName = company.selectedCompany.companyName;
    let decodedString = decodeURIComponent(escape(company.selectedCompany.companyName));
    this.companyName = decodedString;
    // console.log(this.companyName, " <<<<COMPANY NAME");
    if (company.selectedCompany) {
      this.schoolFireNumber = company.selectedCompany.fireNumber;
    }
    if (browser.msie) {
      // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none';
    }
    this.loginInFirebaseOnline();
  }


  toggleUserBlock(event) {
    event.preventDefault();
  }

  openNavSearch(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setNavSearchVisible(true);
  }

  setNavSearchVisible(stat: boolean) {
    // console.log(stat);
    this.isNavSearchVisible = stat;
  }

  getNavSearchVisible() {
    return this.isNavSearchVisible;
  }

  toggleOffsidebar() {
    this.settings.toggleLayoutSetting('offsidebarOpen');
  }

  toggleCollapsedSideabar() {
    this.settings.toggleLayoutSetting('isCollapsed');
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  toggleFullScreen(event) {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
    // Switch icon indicator
    const el = $(this.fsbutton.nativeElement);
    if (screenfull.isFullscreen) {
      el.children('em')
          .removeClass('fa-expand')
          .addClass('fa-compress');
    } else {
      el.children('em')
          .removeClass('fa-compress')
          .addClass('fa-expand');
    }
  }

  loginInFirebaseOnline() {
    // login as read only
    // console.log("lOGGIN A FIREBASE%%%%%%%%%%%%%%%%%%%")
    this.afAuth.auth.signInWithEmailAndPassword(environment.emailFirebase, environment.passwordFirebase).then(value => {
      this.loadNotifications();
    }).catch(err => {
      console.log('Something went wrong getting all routes of a school:', err.message);
    });
  }

  getcountnews(event) {
    this.countnews = event;
    this.notificationCount = this.countnews + this.countnotshipped + this.countnotifications;
  }

  getTotalVoucher($event) {
    this.voucherCount = $event;
  }

  getcountnotification(event) {
    this.countnotifications = event;
  }

  getcountnotshippend(event) {
    this.countnotshipped = event;
    // console.log( this.countnotshipped);
    this.notificationCount = this.countnews + this.countnotshipped + this.countnotifications;
  }


  loadNotifications() {
    const month = new Date().getUTCMonth() + 1; // the months are from 0 to 11
    const day = new Date().getUTCDate();
  }

  changeNotification(notify: notification) {
    // console.log(notify);
    this.textNotifiPopUp = notify.text;
    this.keyNotifiPopUp = notify.key;

  }

  openModal() {
    this.openNoveltiesModal = true;
  }

  get companyNameDecoded(): string {
    return decodeURIComponent(this.companyName || '');
  }


}

interface notification {
  key: string;
  text: string;
}
