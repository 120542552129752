import {Menu} from './administrate/class/class.menu';


const Administrar = {
  text: 'Administrar',
  link: '/administrate',
  icon: 'icon-wrench',
  submenu: [
    {
      text: 'Unidad Educativa',
      link: '/administrate/company',
    },
    {
      text: 'Representantes',
      link: '/administrate/representatives',
    },
    {
      text: 'Conductores',
      link: '/administrate/drivers'
    },
    {
      text: 'Rutas',
      link: '/administrate/roads',
      submenu: [
        {
          text: 'Lista Predefinida',
          link: '/administrate/new-predefined'
        },
        {
          text: 'Seleccion de Mapa',
          link: '/administrate/new-map'
        },
        {
          text: 'Reasignación de rutas',
          link: '/administrate/route-reassignment'
        }
      ]
    }
  ]
};

const Importar = {
  text: 'Importar',
  link: '/import',
  icon: 'icon-cloud-upload'
};

const AdminApps = {
  text: 'AdminApps',
  link: '/admin-apps',
  icon: 'fas fa-tablet-alt'
};

const Rutas = {
  text: 'Rutas',
  link: '/roads',
  icon: 'icon-location-pin',
  submenu: [
    {
      text: 'Nueva Ruta',
      link: '/roads',
      submenu: [
        {
          text: 'Lista Predefinida',
          link: '/roads/new-predefined'
        },
        {
          text: 'Seleccion de Mapa',
          link: '/roads/new-map'
        }]
    },
    {
      text: 'Editar Ruta',
      link: '/roads',
      submenu: [
        {
          text: 'Lista Predefinida',
          link: '/roads/edit-predefined'
        },
        {
          text: 'Seleccion de Mapa',
          link: '/roads/edit-map'
        }]
    }
  ]
};

const Reporte = {
  text: 'Reporte',
  link: '/report',
  icon: 'fas fa-file-alt',
  submenu: [
    {
      id: 17,
      link: '/report/use',
      submenu: null,
      text: 'Uso',
    },
    {
      id: 27,
      link: '/report/route-assistance',
      submenu: null,
      text: 'Asistencia por Ruta',
    },
    {
      id: 21,
      link: '/billing/massivepayments',
      submenu: null,
      text: 'Cobros Masivos',
    },
    {
      id: 26,
      link: '/billing/externalinvoice',
      submenu: null,
      text: 'Facturas Externas',
    },
    {
      id: 29,
      link: '/billing/retentions',
      submenu: null,
      text: 'Retenciones',
    },
    {
      id: 30,
      link: '/billing/consultretentions',
      submenu: null,
      text: 'Consultar Retenciones',
    },
    {
      id: 35,
      link: '/billing/consultnotecredit',
      submenu: null,
      text: 'Notas de Credito',
    },
  ]
};

const GestionInstitucional = {
  text: 'gestioninstitucional',
  link: '/management',
  icon: 'fas fa-road',
  submenu: [
    {
      id: 44,
      link: '/management/institutional-routes',
      submenu: null,
      text: 'Routes',
    },
    {
      id: 45,
      link: '/management/institutional-passengers',
      submenu: null,
      text: 'Passengers',
    },
    {
      id: 46,
      link: '/management/institutional-inscriptions',
      submenu: null,
      text: 'Inscriptions',
    },
  ]
};

const Consultas = {
  text: 'Consultas',
  link: '/queries',
  icon: 'icon-magnifier',
  submenu: [
    {
      text: 'Consultas por Condcutor',
      link: '/queries/queries-driver',
    },
    {
      text: 'Consultas por Ruta',
      link: '/queries/queries-route'
    }


  ]
};

const Facturacion = {
  text: 'Facturacion',
  link: '/billing',
  icon: 'fa fa-dollar-sign',
  submenu: [
    {
      text: 'Consulta de Facturas',
      link: '/billing',
      submenu: [
        {
          text: 'General',
          link: '/billing/check-general-invoices'
        },
        {
          text: 'Estudiantes',
          link: '/billing/check-students-invoices'
        },
        {
          text: 'Conductores',
          link: '/billing/check-drivers-invoices'
        },
        {
          text: 'Rutas',
          link: '/billing/check-routes-invoices'
        }]
    },
    {
      text: 'Consulta Notas de Credito',
      link: '/billing/check-credit-notes',

    },
    {
      text: 'Generar Facturas',
      link: '/billing',
      submenu: [
        {
          text: 'Estudiantes',
          link: '/billing/generate-student-invoices'
        },
        {
          text: 'Adicionales',
          link: '/billing/generate-additional-invoices'
        }
      ]
    },
    {
      text: 'Modificar Datos Factura',
      link: '/billing/modifu-data-invoices',

    },
    {
      text: 'Configuracion',
      link: '/billing/configuration',

    },
    {
      text: 'Facturas Masivas',
      link: '/billing/massivebilling',
    },


  ]
};

const Home = {
  text: 'Home',
  link: '/home',
  icon: 'fas fa-home'
};

const HomeBlocked = {
  text: 'Home',
  link: '/home/blocked',
  icon: 'fas fa-home'
};

const Dashboard = {
  text: 'Dashboard',
  link: '/dashboard',
  icon: 'icon-speedometer',
  submenu: [
    {
      text: 'Dashbord v1',
      link: '/dashboard/v1'
    },
    {
      text: 'Dashbord v2',
      link: '/dashboard/v2'
    },
    {
      text: 'Dashbord v3',
      link: '/dashboard/v3'
    }
  ]
};

const Widgets = {
  text: 'Widgets',
  link: '/widgets',
  icon: 'icon-grid'
};

const Material = {
  text: 'Material',
  link: '/material',
  icon: 'fa fa-shield-alt',
  submenu: [
    {
      text: 'Widgets',
      link: '/material/widgets'
    },
    {
      text: 'Cards',
      link: '/material/cards'
    },
    {
      text: 'Forms',
      link: '/material/forms'
    },
    {
      text: 'Inputs',
      link: '/material/inputs'
    },
    {
      text: 'Lists',
      link: '/material/lists'
    },
    {
      text: 'Whiteframe',
      link: '/material/whiteframe'
    },
    {
      text: 'Colors',
      link: '/material/colors'
    },
    {
      text: 'ng2Material',
      link: '/material/ngmaterial'
    }
  ],
  'alert': 'new',
  'label': 'badge badge-primary'
};

const Elements = {
  text: 'Elements',
  link: '/elements',
  icon: 'icon-chemistry',
  submenu: [
    {
      text: 'Buttons',
      link: '/elements/buttons'
    },
    {
      text: 'Interaction',
      link: '/elements/interaction'
    },
    {
      text: 'Notification',
      link: '/elements/notification'
    },
    {
      text: 'SweetAlert',
      link: '/elements/sweetalert'
    },
    {
      text: 'Spinners',
      link: '/elements/spinners'
    },
    {
      text: 'Dropdown',
      link: '/elements/dropdown'
    },
    {
      text: 'Nav Tree',
      link: '/elements/navtree'
    },
    {
      text: 'Sortable',
      link: '/elements/sortable'
    },
    {
      text: 'Grid',
      link: '/elements/grid'
    },
    {
      text: 'Grid Masonry',
      link: '/elements/gridmasonry'
    },
    {
      text: 'Typography',
      link: '/elements/typography'
    },
    {
      text: 'Font Icons',
      link: '/elements/iconsfont'
    },
    {
      text: 'Weahter Icons',
      link: '/elements/iconsweather'
    },
    {
      text: 'Colors',
      link: '/elements/colors'
    },
    {
      text: 'Infinite Scroll',
      link: '/elements/infinitescroll'
    }
  ]
};

const Forms = {
  text: 'Forms',
  link: '/forms',
  icon: 'icon-note',
  submenu: [
    {
      text: 'Standard',
      link: '/forms/standard'
    },
    {
      text: 'Extended',
      link: '/forms/extended'
    },
    {
      text: 'Validation',
      link: '/forms/validation'
    },
    {
      text: 'Upload',
      link: '/forms/upload'
    },
    {
      text: 'Image Crop',
      link: '/forms/cropper'
    }
  ]
};

const Charts = {
  text: 'Charts',
  link: '/charts',
  icon: 'icon-graph',
  submenu: [
    {
      text: 'Flot',
      link: '/charts/flot'
    },
    {
      text: 'Radial',
      link: '/charts/radial'
    },
    {
      text: 'ChartJS',
      link: '/charts/chartjs'
    }
  ]
};

const Tables = {
  text: 'Tables',
  link: '/tables',
  icon: 'icon-grid',
  submenu: [
    {
      text: 'Standard',
      link: '/tables/standard'
    },
    {
      text: 'Extended',
      link: '/tables/extended'
    },
    {
      text: 'Data-Tables',
      link: '/tables/datatable'
    },
    {
      text: 'Angular Grid',
      link: '/tables/aggrid'
    },
    {
      text: 'NGxDatatables',
      link: '/tables/ngxdatatable'
    }
  ]
};

const Maps = {
  text: 'Maps',
  link: '/maps',
  icon: 'icon-map',
  submenu: [
    {
      text: 'Google',
      link: '/maps/google'
    },
    {
      text: 'Vector',
      link: '/maps/vector'
    }
  ]
};

const Pages = {
  text: 'Pages',
  link: '/pages',
  icon: 'icon-doc',
  submenu: [
    {
      text: 'Login',
      link: '/login'
    },
    {
      text: 'Register',
      link: '/register'
    },
    {
      text: 'Recover',
      link: '/recover'
    },
    {
      text: 'Lock',
      link: '/lock'
    },
    {
      text: '404',
      link: '/404'
    },
    {
      text: '500',
      link: '/500'
    },
    {
      text: 'Maintenance',
      link: '/maintenance'
    }
  ]
};

const Blog = {
  text: 'Blog',
  link: '/blog',
  icon: 'icon-notebook',
  submenu: [
    {
      text: 'List',
      link: '/blog/list'
    },
    {
      text: 'Post',
      link: '/blog/post'
    },
    {
      text: 'Articles',
      link: '/blog/articles'
    },
    {
      text: 'Article View',
      link: '/blog/articleview'
    }
  ]
};

const Ecommerce = {
  text: 'Ecommerce',
  link: '/ecommerce',
  icon: 'icon-basket-loaded',
  submenu: [
    {
      text: 'Orders',
      link: '/ecommerce/orders'
    },
    {
      text: 'Order View',
      link: '/ecommerce/orderview'
    },
    {
      text: 'Products',
      link: '/ecommerce/products'
    },
    {
      text: 'Product View',
      link: '/ecommerce/productview'
    },
    {
      text: 'Checkout',
      link: '/ecommerce/checkout'
    }
  ]
};

const Extras = {
  text: 'Extras',
  link: '/extras',
  icon: 'icon-cup',
  submenu: [
    {
      text: 'Contacts',
      link: '/extras/contacts'
    },
    {
      text: 'Contact details',
      link: '/extras/contactdetails'
    },
    {
      text: 'Projects',
      link: '/extras/projects'
    },
    {
      text: 'Projects details',
      link: '/extras/projectsdetails'
    },
    {
      text: 'Team Viewer',
      link: '/extras/teamviewer'
    },
    {
      text: 'Social Board',
      link: '/extras/socialboard'
    },
    {
      text: 'Vote links',
      link: '/extras/votelinks'
    },
    {
      text: 'Bug tracker',
      link: '/extras/bugtracker'
    },
    {
      text: 'Faq',
      link: '/extras/faq'
    },
    {
      text: 'Help center',
      link: '/extras/helpcenter'
    },
    {
      text: 'Followers',
      link: '/extras/followers'
    },
    {
      text: 'Settings',
      link: '/extras/settings'
    },
    {
      text: 'Plans',
      link: '/extras/plans'
    },
    {
      text: 'File manager',
      link: '/extras/filemanager'
    },
    {
      text: 'Forum',
      link: '/extras/forum'
    },
    {
      text: 'Mailbox',
      link: '/extras/mailbox'
    },
    {
      text: 'Timeline',
      link: '/extras/timeline'
    },
    {
      text: 'Calendar',
      link: '/extras/calendar'
    },
    {
      text: 'Invoice',
      link: '/extras/invoice'
    },
    {
      text: 'Search',
      link: '/extras/search'
    },
    {
      text: 'Todo list',
      link: '/extras/todolist'
    },
    {
      text: 'Profile',
      link: '/extras/profile'
    },
    {
      text: 'Code editor',
      link: '/extras/codeeditor'
    }
  ]
};

const headingMibuseta = {
  text: 'Mi Buseta',
  heading: true
};

const headingMain = {
  text: 'Main Navigation',
  heading: true
};

const headingComponents = {
  text: 'Components',
  heading: true
};

const headingMore = {
  text: 'More',
  heading: true
};

/*export const menu = [
  Home,
  Administrar,
  Importar,
  Rutas,
  Reporte,
  Consultas,
  Facturacion,
  headingMain,
  Dashboard,
  Widgets,
  Material,
  Elements,
  Forms,
  Charts,
  Tables,
  Maps,
  headingMore,
  Pages,
  Blog,
  Ecommerce,
  Extras
];*/

/*export const menuAdministrateMIB = [
  headingMibuseta,
  Home,
  Administrar,
  Importar,
  Rutas,
  Reporte,
  Consultas,
  Facturacion
];*/

/*export const menuAdministrateMIBNoLogin = [
  headingMibuseta
];*/

/**
 * No se utiliza ningun menu, ya que todos se carga desde el backend
 * por el momento solo se utiliza los menus de abajo para bloquear
 * las unidades educativas
 * */

// @ts-ignore
export const menuBlocked = [HomeBlocked, Reporte];

// @ts-ignore
export const menuDisabled = [Home];
