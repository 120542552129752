import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatPaginator, MatTableDataSource, PageEvent } from '@angular/material';
import { Driver } from '../../class/class.driver';
import { DriverService } from '../../../../services/driver/driver.service'
//import { $ } from 'protractor';
import * as $ from 'jquery';
import { log } from 'util';
import { User } from '../../class/class.user';
import { LockScreenService } from '../../../../services/lock-screen/lock-screen.service'
import {TokenService} from '../../../../models/services/token.service';
const swal = require('sweetalert');


@Component({
  selector: 'app-list-drivers',
  templateUrl: './list-drivers.component.html',
  styleUrls: ['./list-drivers.component.scss']
})
export class ListDriversComponent implements OnInit {
  // displayedColumns: string[] = ['position', 'name', 'weight'];
  // dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  @Output() disableDataDriver = new EventEmitter<object>();
  flag_show_data: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  //===================INICIALIZATE VARIABLES------------------------
  displayedColumns;
  dataSource;
  pageEvent: PageEvent;
  pageIndex: number;
  pageSize: number = 10;
  pageSizeOptions=[5, 10, 50, 100];
  length: number;
  filter: string = "";
  bandera = true;
  driverSelected;
  driverforPaginate;
  user;

  //--------------OUTPUT VARIABLES------------------------
  @Output() selectedCarDriver = new EventEmitter<object>();
  @Output() changeStateDriver = new EventEmitter<object>();

  // ------------- CONSTRUCTOR --------------------------
  constructor(private driverService: DriverService, private lockScreenService: LockScreenService, private tokenService: TokenService) { }



  ngOnInit() {
    this.user = this.tokenService.USER;
  }

  //------------------- ACTIVE DRIVER  ------------------------
  fun_activate_driver_data() {
    let driveraux: Driver = this.driverSelected;
    driveraux.state = 1;
    this.disableDataDriver.emit(driveraux)// send data with state of driver 0 for disable, we dont gonna delete data of the database
  }


  //-------------------------USER PRESS EDIT BUTTON GENERATE A EVENT ---------------------------------------
  //function that gets selected driver from list-drivers container
  fun_edit_driver(driver: Driver) {
    this.lockScreenService.fun_lock_screen_while_loading();
    this.driverService.getByDriverId(driver.id)
      .subscribe(resp => {
        //console.log(resp);
        let driver = resp.body; // data got in the service
        let driverstring = JSON.stringify(driver);//parse data to string
        let driverData = JSON.parse(driverstring);//parse string to data
        this.selectedCarDriver.emit(driverData);
      }, error => console.error(error)) // error path);
  }

  //------------------------------------CHANGE STATE OF DRIVER DATA----------------------------------------------//
  fun_change_state_driver_data($event, driver: Driver) {
    let typeMessage;
    let mensajeButton;
    if (driver.state == 1) {//VERIFY IF THE DRIVER IS ACTIVED AND CHANGE THE MESSAGE AND ICON OF THE POPUP MESSAGE
      typeMessage = 'error';
      mensajeButton = "Si, desactivar."
    } else {
      typeMessage = 'info';
      mensajeButton = "Si, activar."
    }
    swal(
      {
        title: '¿Está seguro que desea realizar esta acción?',
        text: 'Se cambiará el estado del conductor, afectando las acciones enlazadas al mismo',
        type: typeMessage,
        showCancelButton: true,
        confirmButtonColor: '#FF0000',
        confirmButtonText: mensajeButton,
        cancelButtonText: 'Cancelar',
        closeOnConfirm: true,
        closeOnCancel: true
      }, (isConfirm) => {
        if (isConfirm) {//if the user calls yes the gonna be desactivated
          let driveraux: Driver = driver;
          if (driveraux.state == 0) {
            driveraux.state = 1;
          } else if (driveraux.state == 1) {
            driveraux.state = 0;
          }
          this.changeStateDriver.emit(driveraux); //send data to father
        } else {
          $event.target.checked = !$event.target.checked;//return to last value if was checked or not checked
        }
      }
    );
  }

  search_data(){
    //console.log(this.filter);
    this.getServerDataFirstTime(0, this.pageSize, this.filter);
  }

  applyFilterv2(valor){
    
    if (valor.length == 0){
      this.getServerDataFirstTime(0, this.pageSize, $("#filter_advisor").val());
    }
    
  }


  //------------------------------------ FILTER----------------------------------------------//
  applyFilter($event) {
    //let filterValue: string = $("#filter_driver").val();//get data from view
    //this.filter = filterValue;
    //console.log(this.filter);
    this.dataSource.filter = this.filter.trim().toLowerCase();
    //this.getServerDataFirstTime(0, this.pageSize, this.filter); // set a first data paginate fro set in the first page
  }

  //------------------------------------ LOAD AND PAGINATE DATA FIRST TIME----------------------------------------------//
  getServerDataFirstTime(from, amount, filter) {// FROM : ITEM INDEX; AMOUNT: AMOUNT OF ITEMS TO LOAD; FILTER: STRING FOR FILTER
    document.getElementById("cover").style.display = "block";
    this.driverService.getForSearchFilterPaginationByConsortiumID(this.user.consortium.id, from * amount, amount, filter)
      .subscribe(resp => {
        //console.log(resp);
        this.pageIndex = from;
        let drivers = resp.body.result; // data got in the service
        this.length = resp.body.total;
        // set drivers like driver data
        let driverstring = JSON.stringify(drivers);//parse data to string
        this.driverforPaginate = JSON.parse(driverstring);//parse string to data
        this.fun_paginate_data(this.driverforPaginate);
        this.length = resp.body.total;

        if(resp.body.total == 0){
          swal("Aviso", "No se ha encontrado información.", "info");
        }
        document.getElementById("cover").style.display = "none";
      }, error => {
        //console.error(error);
        document.getElementById("cover").style.display = "none";
      }
      ) // error path);

  }
  //------------------------------------PAGINATE DATA----------------------------------------------//
  fun_paginate_data(drivers: Driver[]) {
    this.displayedColumns = ['position', 'id', 'identification', 'lastName', 'firstName',  'email', 'password', 'estado', 'editar'];
    this.dataSource = new MatTableDataSource<Driver>(drivers);
    this.dataSource.paginator = this.paginator;
    // console.log(this.dataSource);
    this.flag_show_data = true;
  }

  //------------------------------------ PAGINATE DATA LOADED FROM THE FIRST TIME METHOD----------------------------------------------//
  getServerDataPaginateTime(from, amount, filter) {
    //console.log($event);
    // this.lockScreenService.fun_lock_screen_while_loading();
    this.driverService.getForSearchFilterPaginationByConsortiumID(this.user.consortium.id, from * amount, amount, filter)
      .subscribe(resp => {
        //console.log(resp);
        this.pageIndex = from;
        let drivers = resp.body.result; // data got in the service
        this.length = resp.body.total;
        let driverstring = JSON.stringify(drivers);//parse data to string
        this.driverforPaginate = JSON.parse(driverstring);//parse string to data
        this.dataSource = this.driverforPaginate;
        this.length = resp.body.total;
        // this.lockScreenService.fun_unlock_loading_screen();
      }, error => {
        console.error(error);
        // this.lockScreenService.fun_unlock_loading_screen();
      }) // error path);
  }
  //------------------------------------ PAGINATE BUTTONS----------------------------------------------//
  getDataPaginateButtons($event?: PageEvent) {//paginate function
    let nextCount = $event.pageIndex;//GET PAGE INDEX FROM VIEW
    this.pageSize=$event.pageSize;
    this.getServerDataPaginateTime(nextCount, this.pageSize, this.filter);
    // console.log($event);
    return $event;
  }
  //------------------------------------ CLEAR FIELDS----------------------------------------------//
  fun_clear_fields() {
    this.filter = '';
    $("#filter_driver").val('')
  }

  //--------------------- CHANGE ORDER IN THE DATA (testing only) --------
  sortData($event) {
    // console.log($event);
  }


}
